import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import HourglassEmptyOutlinedIcon from
  '@material-ui/icons/HourglassEmptyOutlined';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const SessionAccessStatusBusy = ({ reload }) => {
  const classes = useStyles();

  const handleClick = () => {
    reload();
  };

  return (
    <StatusCard
      actions={(
        <Button
          color="primary"
          onClick={handleClick}
          variant="contained"
        >
          {'Try again'}
        </Button>
      )}
      content={(
        <Typography
          gutterBottom
          variant="body1"
        >
          {'We’re sorry but we are experiencing unusually high traffic and'
          + ' all demo slots are in use right now. Please try again later'
          + ' or refresh the page.'}
        </Typography>
      )}
      header={'All virtual demo slots are currently in use'}
      icon={(
        <Avatar className={classes.success}>
          <HourglassEmptyOutlinedIcon />
        </Avatar>
      )}
    />
  );
};

SessionAccessStatusBusy.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default SessionAccessStatusBusy;
