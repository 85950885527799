import React from 'react';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import SessionAccessStatus from '../SessionAccessStatus';

const SessionAccessPage = ({ match }) => (
  <Page>
    <PageItem>
      <SessionAccessStatus id={match.params.id} />
    </PageItem>
    <PageItem>
      <Carousel />
    </PageItem>
  </Page>
);

export default SessionAccessPage;
