import React from 'react';
import Typography from '@material-ui/core/Typography';

const InfoAboutDemoText = () => (
  <>
    <Typography
      gutterBottom
      variant="body1"
    >
      {'Your demo should be ready in less than a minute. Check out how '
      + 'Access Commander works in the panel below while you wait.'}
    </Typography>
    <Typography
      display="block"
      gutterBottom
      variant="caption"
    >
      {'* This demo contains emulated devices, which will appear disabled'}
      {' and whose direct configuration cannot be accessed. '}
      {'Once started, your demo session will last for 2 hours before expiring.'}
    </Typography>
  </>
);

export default InfoAboutDemoText;
