import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AppContainer from '../AppContainer';
import logo from '../../images/logo_2n.png';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
}));

const AppBar = () => {
  const classes = useStyles();

  return (
    <>
      <MaterialAppBar style={{ backgroundColor: '#000' }}>
        <Toolbar disableGutters>
          <AppContainer>
            <Grid
              alignItems="center"
              container
              direction="row"
              justify="flex-start"
              spacing={2}
            >
              <Grid item>
                <Grid
                  alignContent="center"
                  container
                >
                  <img
                    alt="Logo"
                    height="28"
                    src={logo}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {'Access Commander DEMO'}
                </Typography>
              </Grid>
              <Box flexGrow={1} />
              <Grid item>
                <Typography variant="subtitle1">
                  <Link
                    href="https://go.2n.com/contact-form?utm_source=acom-demo"
                    style={{ color: '#FFF' }}
                  >
                    {'Contact us'}
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <Link href="https://www.2n.com" style={{ color: '#FFF' }}>
                    {'www.2n.com'}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </AppContainer>
        </Toolbar>
      </MaterialAppBar>
      <div className={classes.appBarSpacer} />
    </>
  );
};

export default AppBar;
