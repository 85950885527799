import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CA, US, allCountries } from 'country-region-data';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import businessProfiles from './businessProfiles';

const RegisterForm = ({
  companyName,
  contactAccepted,
  customerType,
  firstName,
  gdprAccepted,
  lastName,
  onChange,
  otherTypeValue,
  phoneNumber,
  upcomingProject,
  country,
  postalCode,
}) => {
  const handleChange = (event) => {
    onChange(event.target.name, event.target.value);
  };

  const handleCountryChange = (event, value) => {
    onChange('country', value.code);
  };

  const handleStateChange = (event, value) => {
    onChange('state', value.code);
  };

  const handleChangeCheckbox = (event) => {
    onChange(event.target.name, event.target.checked);
  };

  const DialogTexts = [
    // eslint-disable-next-line max-len
    '1. 2N Access Commander is a software product of 2N TELEKOMUNIKACE a.s., ID No. 26183960, with registered office at Praha 4, Modřanská 621/72, Zip Code 14301, the Czech Republic (the Manufacturer).',
    // eslint-disable-next-line max-len
    '2. The Manufacturer offers a demonstration environment which offers visitors the opportunity to experience the interface of 2N Access Commander (the Product). Visitors will register to request access to the demonstration via the 2N Access Commander demo web page and shall be sent link which they may follow, allowing them access to a demonstration instance of 2N Access Commander.',
    // eslint-disable-next-line max-len
    '3. The Manufacturer makes no representations or warranties in relation to the demo or the information and materials provided on the demo.',
    // eslint-disable-next-line max-len
    '4. Every effort is made to keep the demo up and running smoothly. However, the Manufacturer takes no responsibility for, and will not be liable for, the demo being temporarily unavailable due to technical issues beyond its control. ',
    // eslint-disable-next-line max-len
    '5. The 2N Access Commander demo may contain elements which are not consistent with the 2N Access Commander itself for example, hardware devices shown in the demo are emulated devices, not real ones. Errors in the 2N Access Commander demo may also occur (even those which could result in serious consequences and/or damages), though it is not very likely. ',
    // eslint-disable-next-line max-len
    '6. The Manufacturer reserves the right to terminate the demo at any time.',
    // eslint-disable-next-line max-len
    '7. Users of the demo will acquire no rights to the 2N Access Commander software.',
    // eslint-disable-next-line max-len
    '8. The Manufacturer owns and retains all rights, legal claims and shares in any and all patents, trademarks, industrial or utility models, copyrights, business secrets and other rights to intellectual and industrial property applying to the Product. Participation in the demo by the Customer does not establish any licence for use of a trademark, corporate name, patent, industrial or utility model or known-how owned by the Manufacturer and the Customer shall not use any rights corresponding to intellectual or industrial property owned by the Manufacturer, in relation to the Product, unless the Manufacturer grants written consent to rights corresponding to intellectual property or industrial property owned by the Manufacturer in cases precisely defined in the given consent to use.',
    // eslint-disable-next-line max-len
    '9. The Manufacturer is not liable for any loss, damage, or injury of any nature whatsoever (including immaterial damage) sustained by the Customer or any third parties in relation to the Customer’s use of the demo.',
    // eslint-disable-next-line max-len
    '10. After reading the text of this document, Privacy Policy and careful consideration of all involved risks, the Customer agrees to try the demo by providing acknowledgement of acceptance in the registration form.',
  ];

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box my={2}>
        <Typography>
          {'Provide us some details and we’ll send you an '
            + 'instant access link to the demo.'}
        </Typography>
        <Typography variant="h6">
          {'Personal'}
        </Typography>
        <Box my={2}>
          <Grid
            alignItems="center"
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs>
              <TextField
                fullWidth
                label="First name"
                name="firstName"
                onChange={handleChange}
                placeholder="Enter your first name"
                required
                value={firstName.value}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Surname"
                name="lastName"
                onChange={handleChange}
                placeholder="Enter your surname"
                required
                value={lastName.value}
              />
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <Grid
            alignItems="center"
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs>
              <Autocomplete
                autoHighlight
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.code === value.code}
                id="country-select-demo"
                name="country"
                onChange={handleCountryChange}
                options={allCountries.map((option) => ({
                  code: option[1],
                  label: option[0],
                }))}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    label="Country"
                    name="country"
                    required
                  />
                )}
                renderOption={(option) => option.label}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="GSM number"
                name="phoneNumber"
                onChange={handleChange}
                placeholder="Enter your number"
                value={phoneNumber.value}
              />
            </Grid>
          </Grid>
          {(country.value === 'US' || country.value === 'CA') && (
            <Grid
              alignItems="center"
              container
              direction="row"
              spacing={2}
            >
              <Grid item xs>
                <Autocomplete
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  id="state-select-demo"
                  name="state"
                  onChange={handleStateChange}
                  options={(country.value === 'US' ? US : CA)[2].map((option) => ({
                    code: option[0],
                    label: option[0],
                  }))}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      label="State"
                      name="state"
                      required
                    />
                  )}
                  renderOption={(option) => option.label}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Postal code"
                  name="postalCode"
                  onChange={handleChange}
                  placeholder="Enter your postalCode"
                  required
                  value={postalCode.value}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Box my={2}>
        <Typography variant="h6">
          {'Others'}
        </Typography>
        <Box my={2}>
          <TextField
            fullWidth
            label="Company name"
            name="companyName"
            onChange={handleChange}
            placeholder="Enter your company name"
            required
            value={companyName.value}
          />
        </Box>
        <Box my={2}>
          <Grid
            alignItems="center"
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs>
              <TextField
                fullWidth
                label="Which best describes you"
                name="customerType"
                onChange={handleChange}
                select
                value={customerType.value}
              >
                {businessProfiles.map((profile) => (
                  <MenuItem
                    key={profile.replace(' ', '').toLowerCase()}
                    value={profile}
                  >
                    {profile}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Specify it, please"
                name="otherTypeValue"
                onChange={handleChange}
                value={otherTypeValue.value}
              />
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <TextField
            fullWidth
            label="Upcoming project"
            name="upcomingProject"
            onChange={handleChange}
            required
            select
            value={upcomingProject.value}
          >
            <MenuItem value={'NoProject'}>{'No Project'}</MenuItem>
            <MenuItem value={'Immediate'}>{'Immediate'}</MenuItem>
            <MenuItem value={'In3Months'}>
              {'In 3 Months'}
            </MenuItem>
            <MenuItem value={'In6Months'}>
              {'In 6 Months'}
            </MenuItem>
            <MenuItem value={'InAYearOrLater'}>
              {'In A Year Or Later'}
            </MenuItem>
          </TextField>
        </Box>
        <Box my={2}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={gdprAccepted.value}
                color="primary"
                name="gdprAccepted"
                onChange={handleChangeCheckbox}
              />
            )}
            label="I agree to 2N Access Commander's Terms & Conditions *"
          />
          <IconButton
            aria-label="delete"
            onClick={handleOpenDialog}
          >
            <LibraryBooksIcon />
          </IconButton>
        </Box>
        <Box my={2}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={contactAccepted.value}
                color="primary"
                name="contactAccepted"
                onChange={handleChangeCheckbox}
              />
            )}
            // eslint-disable-next-line max-len
            label="I accept that 2N may contact me to ask for my feedback on 2N Access Commander and to discuss my upcoming projects."
          />
        </Box>
      </Box>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth={'lg'}
        onClose={handleCloseDialog}
        open={openDialog}
      >
        <DialogTitle id="alert-dialog-title">
          {'Terms of Use'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              {'Project: Access Commander demo'}
            </Typography>
            <List aria-label="main mailbox folders">
              {DialogTexts.map((number) => (
                <ListItem key={number}>
                  <ListItemText primary={number} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={handleCloseDialog}
            variant="outlined"
          >
            {'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RegisterForm.propTypes = {
  companyName: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  contactAccepted: PropTypes.shape({
    value: PropTypes.bool,
  }),
  customerType: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  firstName: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  gdprAccepted: PropTypes.shape({
    value: PropTypes.bool,
  }),
  lastName: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
  otherTypeValue: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  phoneNumber: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  upcomingProject: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  country: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
  postalCode: PropTypes.shape({
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
  }),
};

RegisterForm.defaultProps = {
  companyName: {
    value: '',
    isInvalid: false,
  },
  contactAccepted: {
    value: true,
  },
  customerType: {
    value: '',
    isInvalid: false,
  },
  firstName: {
    value: '',
    isInvalid: false,
  },
  gdprAccepted: {
    value: false,
  },
  lastName: {
    value: '',
    isInvalid: false,
  },
  otherTypeValue: {
    value: '',
    isInvalid: true,
  },
  phoneNumber: {
    value: '',
    isInvalid: false,
  },
  upcomingProject: {
    value: '',
    isInvalid: false,
  },
  country: {
    value: '',
    isInvalid: false,
  },
  postalCode: {
    value: '',
    isInvalid: false,
  },
};

export default RegisterForm;
