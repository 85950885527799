import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const Page = ({ children }) => (
  <Grid
    container
    direction="column"
    spacing={2}
  >
    {children}
  </Grid>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
