import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const CarouselCard = ({
  img,
  text,
}) => (
  <>
    <CardContent>
      <Typography>
        {text}
      </Typography>
    </CardContent>
    <CardMedia
      component="img"
      src={img.src}
      title={img.title}
    />
  </>
);

CarouselCard.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
  }),
  text: PropTypes.string,
};

CarouselCard.defaultProps = {
  img: {
    src: null,
    title: null,
  },
  text: null,
};

export default CarouselCard;
