import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const RegistrationSuccessPage = () => {
  const classes = useStyles();

  return (
    <Page>
      <PageItem>
        <StatusCard
          content={(
            <>
              <Typography
                gutterBottom
                variant="body1"
              >
                {'Within a few minutes you will recive the link* for the demo.'
                + ' If the message doesn\'t apper, please check your spam.'}
              </Typography>
              <Typography
                display="block"
                gutterBottom
                variant="caption"
              >
                {'* The link for the first demo launch is valid for 24 hours.'}
              </Typography>
            </>
          )}
          header={'The link for demo was sent to your e-mail'}
          icon={(
            <Avatar className={classes.success}>
              <MailOutlineIcon />
            </Avatar>
          )}
        />
      </PageItem>
      <PageItem>
        <Carousel />
      </PageItem>
    </Page>
  );
};

export default RegistrationSuccessPage;
