import React from 'react';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import SessionAccessStatusError from '../SessionAccessStatusError';

const ErrorPage = ({ history }) => {
  const getRoot = () => {
    history.push('/');
  };

  return (
    <Page>
      <PageItem>
        <SessionAccessStatusError reload={getRoot} />
      </PageItem>
      <PageItem>
        <Carousel />
      </PageItem>
    </Page>
  );
};

export default ErrorPage;
