import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const StatusCard = ({
  actions,
  content,
  header,
  icon,
}) => (
  <Card>
    <CardHeader
      avatar={icon}
      title={header}
      titleTypographyProps={{ variant: 'h5' }}
    />
    <CardContent>
      {content}
    </CardContent>
    <CardActions>
      {actions}
    </CardActions>
  </Card>
);

StatusCard.propTypes = {
  actions: PropTypes.node,
  content: PropTypes.node,
  header: PropTypes.string,
  icon: PropTypes.node,
};

StatusCard.defaultProps = {
  actions: null,
  content: null,
  header: null,
  icon: null,
};

export default StatusCard;
