/* eslint-disable max-statements */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const LandingPage = ({ history }) => {
  const classes = useStyles();
  const siteKey = `${process.env.SITE_KEY}`;

  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  const [email, setEmail] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleCaptchaReady = () => {
    setIsRecaptchaReady(true);
  };

  const handleClick = () => {
    const path = 'register/email';
    const data = { email };

    window.fetch(
      `${process.env.API_PATH}${path}`,
      {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'g-recaptcha-response': recaptchaRef.current.getValue(),
        },
        method: 'POST',
        mode: 'cors',
      }
    )
      .then((response) => {
        if (response.status === 200) {
          history.push('/registrationsuccess');
        }
        if (response.status === 201) {
          history.push('/register');
        }
      })
      .catch(() => {
        history.push('/error');
      });
  };

  const handleChangeEmail = (event) => {
    const validateEmail = (value) => {
      // eslint-disable-next-line max-len
      const expression = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      return expression.test(String(value).toLowerCase());
    };

    setEmail(event.target.value);

    if (validateEmail(event.target.value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const recaptchaRef = React.createRef();

  return (
    <Page>
      <PageItem>
        <StatusCard
          actions={(
            <Button
              color="primary"
              disabled={email.length < 1
                || !isEmailValid || !isRecaptchaReady}
              onClick={handleClick}
              variant="contained"
            >
              {'Next'}
            </Button>
          )}
          content={(
            <>
              <Box my={2}>
                <TextField
                  error={!isEmailValid}
                  helperText={!isEmailValid && ('Please enter a valid email'
                  + ' address (example@example.com)')}
                  label="E-mail"
                  onChange={handleChangeEmail}
                  placeholder="Enter your e-mail"
                  required
                  value={email}
                />
              </Box>
              <Box my={2}>
                <ReCAPTCHA
                  onChange={handleCaptchaReady}
                  ref={recaptchaRef}
                  sitekey={siteKey}
                />
              </Box>
            </>
          )}
          header={
            'Provide us some details and we’ll send you an instant access'
            + ' link to our online demo.'
          }
          icon={(
            <Avatar className={classes.success}>
              <VisibilityIcon />
            </Avatar>
          )}
        />
      </PageItem>
      <PageItem>
        <Carousel />
      </PageItem>
    </Page>
  );
};

export default LandingPage;
