import React from 'react';
import MaterialCarousel from 'react-material-ui-carousel';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { makeStyles } from '@material-ui/core/styles';

import CarouselCard from '../CarouselCard';

import accessRules from '../../images/accessRules.png';
import companies from '../../images/companies.png';
import users from '../../images/users.png';
import zones from '../../images/zones.png';

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  carousel: {
    '& .Button': {
      opacity: `${0.2} !important`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '& .ButtonWrapper:hover > .Button': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '& .Button:hover': {
      opacity: `${1} !important`,
    },
    '& .Indicators .Indicator': {
      color: theme.palette.info.light,
    },
    '& .Indicators .Indicator.Active': {
      color: theme.palette.primary.dark,
    },
  },
}));

const Carousel = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={(
          <Avatar className={classes.info}>
            <EmojiObjectsOutlinedIcon />
          </Avatar>
        )}
        title={'How Access Commander works?'}
        titleTypographyProps={{ variant: 'h5' }}
      />
      <MaterialCarousel
        autoPlay={false}
        className={classes.carousel}
      >
        <CarouselCard
          img={{ src: companies, title: 'Companies' }}
          text={'Companies may be used to divide companies in the same '
            + 'building or to define separate sites.'}
        />
        <CarouselCard
          img={{ src: zones, title: 'Zones' }}
          text={'Zones are groups of devices which give access to an'
          + ' area with the same access restrictions.'}
        />
        <CarouselCard
          img={{ src: users, title: 'Users' }}
          text={'Users are placed into groups, which may be granted specific'
          + ' access rules. Users may belong to several groups.'}
        />
        <CarouselCard
          img={{ src: accessRules, title: 'Access Rules' }}
          text={'Access rules allow groups of users to access zones of'
          + ' devices according to time profiles.'}
        />
      </MaterialCarousel>
    </Card>
  );
};

export default Carousel;
