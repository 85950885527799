import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import AppContainer from '../AppContainer';
import Banner from '../Banner';
import ErrorPage from '../ErrorPage';
import ExpiredPage from '../ExpiredPage';
import SessionAccessPage from '../SessionAccessPage';
import LandingPage from '../LandingPage';
import RegistrationSuccessPage from '../RegistrationSuccessPage';
import RegisterFormPage from '../RegisterFormPage';
import LogoutPage from '../LogoutPage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
}));

const MainContent = () => {
  const classes = useStyles();

  return (
    <AppContainer className={classes.wrapper}>
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid
          alignItems="center"
          container
          item
          sm={6}
          xs={12}
        >
          <Banner />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
        >
          <Switch>
            <Route
              component={ErrorPage}
              path="/error"
            />
            <Route
              component={ExpiredPage}
              path="/expired"
            />
            <Route
              component={RegistrationSuccessPage}
              path="/registrationsuccess"
            />
            <Route
              component={RegisterFormPage}
              path="/register"
            />
            <Route
              component={SessionAccessPage}
              path="/session/:id"
            />
            <Route
              component={LogoutPage}
              path="/logout"
            />
            <Route
              component={LandingPage}
              path="/"
            />
          </Switch>
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default MainContent;
