import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const SessionAccessStatusRunning = ({ hostname }) => {
  const classes = useStyles();

  const goToAcom = () => {
    window.location = `https://${hostname}`;
  };

  return (
    <StatusCard
      actions={(
        <Button
          color="primary"
          onClick={goToAcom}
          variant="contained"
        >
          {'Let\'s start'}
        </Button>
      )}
      content={(
        <>
          <Typography
            gutterBottom
            variant="body1"
          >
            {'This demo includes emulated devices. They appear disabled and'
            + ' you cannot sync them or access their direct'
            + ' configuration pages.'}
          </Typography>
          <Typography
            display="block"
            gutterBottom
            variant="caption"
          >
            {'* Once started, your demo session will last for'
            + ' 2 hours before expiring.'}
          </Typography>
        </>
      )}
      header={'The demo is ready'}
      icon={(
        <Avatar className={classes.success}>
          <DoneIcon />
        </Avatar>
      )}

    />
  );
};

SessionAccessStatusRunning.propTypes = {
  hostname: PropTypes.string.isRequired,
};

export default SessionAccessStatusRunning;
