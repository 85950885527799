import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const LogoutPage = () => {
  const classes = useStyles();

  return (
    <Page>
      <PageItem>
        <StatusCard
          actions={(
            <Button
              color="primary"
              // eslint-disable-next-line max-len
              href="https://docs.google.com/forms/d/e/1FAIpQLSc3fflCdK8U1gJnqHWhGMJodeb1AdaEIEZjVZg8Nx6e_n6QmA/viewform"
              target="_blank"
              variant="contained"
            >
              {'Give us feedback'}
            </Button>
          )}
          content={(
            <>
              <Typography
                gutterBottom
                variant="body1"
              >
                {'To sign in to the 2N® Access Commander demo again,'
                + ' use the link from the registration email.'}
              </Typography>
              <Typography
                display="block"
                gutterBottom
                variant="caption"
              >
                {'* Once started, your demo session will last for 2 hours'
                + ' before expiring.'}
              </Typography>
            </>
          )}
          header={'Successfully logged out'}
          icon={(
            <Avatar className={classes.success}>
              <CheckIcon />
            </Avatar>
          )}
        />
      </PageItem>
      <PageItem>
        <Carousel />
      </PageItem>
    </Page>
  );
};

export default LogoutPage;
