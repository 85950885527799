import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';

import Page from '../Page';
import PageItem from '../PageItem';
import RegisterForm from '../RegisterForm';
import StatusCard from '../StatusCard';

function init() {
  return {
    companyName: {
      value: '',
      isInvalid: true,
    },
    contactAccepted: {
      value: false,
    },
    country: {
      value: '',
      isInvalid: true,
    },
    state: {
      value: '',
      isInvalid: true,
    },
    postalCode: {
      value: '',
      isInvalid: true,
    },
    customerType: {
      value: '',
      isInvalid: false,
    },
    firstName: {
      value: '',
      isInvalid: true,
    },
    gdprAccepted: {
      value: false,
      isInvalid: true,
    },
    lastName: {
      value: '',
      isInvalid: true,
    },
    otherTypeValue: {
      value: '',
      isInvalid: false,
    },
    phoneNumber: {
      value: '',
      isInvalid: false,
    },
    upcomingProject: {
      value: '',
      isInvalid: true,
    },
  };
}

/* eslint-disable complexity */
function reducer(state, action) {
  switch (action.type) {
    case 'firstName':
    case 'lastName':
    case 'companyName':
    case 'state':
    case 'postalCode':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          value: action.value,
          isInvalid: action.value.length < 1
                    || action.value.length > 64,
        },
      };
    case 'contactAccepted':
    case 'customerType':
    case 'phoneNumber':
    case 'otherTypeValue':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          value: action.value,
        },
      };
    case 'gdprAccepted':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          value: action.value,
          isInvalid: !action.value,
        },
      };
    case 'country':
      return {
        ...state,
        country: {
          ...state.country,
          value: action.value,
          isInvalid: action.value.length < 1,
        },
        state: {
          value: '',
          isInvalid: action.value === 'US' || action.value === 'CA',
        },
        postalCode: {
          value: (action.value === 'US' || action.value === 'CA') ? state.postalCode.value : '',
          isInvalid: (action.value === 'US' || action.value === 'CA')
            && (state.postalCode.value.length < 1 || state.postalCode.value.length > 64),
        },
      };
    case 'upcomingProject':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          value: action.value,
          isInvalid: action.value.length < 1,
        },
      };
    default:
      throw new Error();
  }
}

const RegisterFormPage = ({ history }) => {
  const [state, dispatch] = useReducer(
    reducer,
    init()
  );

  const handleChange = (type, value) => {
    dispatch({ type, value });
  };

  const handleClick = () => {
    const path = 'register/data';
    const data = {
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      companyName: state.companyName.value,
      country: state.country.value,
      state: state.state.value,
      postalCode: state.postalCode.value,
      phoneNumber: state.phoneNumber.value,
      upcomingProject: state.upcomingProject.value,
      customerType: state.customerType.value,
      otherTypeValue: state.otherTypeValue.value,
      gdprAccepted: state.gdprAccepted.value,
      contactAccepted: state.contactAccepted.value,
    };

    window.fetch(
      `${process.env.API_PATH}${path}`,
      {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
      }
    )
      .then((response) => {
        if (response.ok) {
          history.push('/registrationsuccess');
        }
      })
      .catch(() => {
        history.push('/error');
      });
  };

  return (
    <Page>
      <PageItem>
        <StatusCard
          actions={(
            <Button
              color="primary"
              disabled={(
                state.firstName.isInvalid
                || state.lastName.isInvalid
                || state.companyName.isInvalid
                || state.country.isInvalid
                || state.state.isInvalid
                || state.postalCode.isInvalid
                || state.upcomingProject.isInvalid
                || state.otherTypeValue.isInvalid
                || state.gdprAccepted.isInvalid
              )}
              onClick={handleClick}
              variant="contained"
            >
              {'Get the demo'}
            </Button>
          )}
          content={(
            <RegisterForm
              companyName={state.companyName}
              contactAccepted={state.contactAccepted}
              country={state.country}
              customerType={state.customerType}
              firstName={state.firstName}
              gdprAccepted={state.gdprAccepted}
              lastName={state.lastName}
              onChange={handleChange}
              otherTypeValue={state.otherTypeValue}
              phoneNumber={state.phoneNumber}
              postalCode={state.postalCode}
              upcomingProject={state.upcomingProject}
            />
          )}
          header={'Request your instant online demo'}
        />
      </PageItem>
    </Page>
  );
};

export default RegisterFormPage;
