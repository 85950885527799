import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

const AppContainer = ({ children, ...props }) => (
  <Container maxWidth="lg" {...props}>
    {children}
  </Container>
);

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContainer;
