import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TimerIcon from '@material-ui/icons/Timer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const SessionAccessStatusExpired = () => {
  const classes = useStyles();

  const handleClick = () => {
    history.push('/');
  };

  const history = useHistory();

  return (
    <StatusCard
      actions={(
        <Button
          color="primary"
          onClick={handleClick}
        >
          {'Repeat the demo'}
        </Button>
      )}
      content={(
        <Typography
          gutterBottom
          variant="body1"
        >
          {'Your demo has expired. Please go back to acdemo.2n.cz and '
            + 're-enter your e-mail for a new link to repeat the demo.'}
        </Typography>
      )}
      header={'Your demo has expired'}
      icon={(
        <Avatar className={classes.warning}>
          <TimerIcon />
        </Avatar>
      )}
    />
  );
};

export default SessionAccessStatusExpired;
