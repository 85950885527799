export default [
  'Architecture and Design Company',
  'Construction Company',
  'Construction Supervision Company',
  'Consulting Company',
  'Distributor',
  'Electrical Contractor',
  'End User',
  'Installation Service Company',
  'Integration partner',
  'Integrator',
  'Manufacturer HW/SW',
  'Other',
  'Project Planning Company',
  'Property Management Company',
  'Real Estate Developer',
  'Reseller',
  'Subdistributor',
  'Telephony Operator',
];
