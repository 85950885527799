import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const SessionAccessStatusError = ({ reload }) => {
  const classes = useStyles();

  const handleClick = () => {
    reload();
  };

  return (
    <StatusCard
      actions={(
        <Button
          color="primary"
          onClick={handleClick}
        >
          {'Back to registration'}
        </Button>
      )}
      content={(
        <span>
          {'We\'re sorry, something unexpected happened. Please try '
          + 'again later.'}
        </span>
      )}
      header={'Oops, something went wrong.'}
      icon={(
        <Avatar className={classes.error}>
          <ErrorOutlineIcon />
        </Avatar>
      )}
    />
  );
};

SessionAccessStatusError.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default SessionAccessStatusError;
