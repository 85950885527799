import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import SessionAccessStatusBusy from '../SessionAccessStatusBusy';
import SessionAccessStatusError from '../SessionAccessStatusError';
import SessionAccessStatusExpired from '../SessionAccessStatusExpired';
import SessionAccessStatusRunning from '../SessionAccessStatusRunning';
import SessionAccessStatusStarting from '../SessionAccessStatusStarting';

const SessionAccessStatus = ({ id }) => {
  const [sessionState, setSessionState] = useState({ status: 0 });

  const tryAgainAfterDelay = () => (
    setTimeout(
      () => getSessionStatus(),
      5000
    )
  );

  const setStartingAndTryAgain = () => {
    setSessionState({ status: 0 });
    tryAgainAfterDelay();
  };

  useEffect(() => {
    tryAgainAfterDelay();
  }, []);

  const getSessionStatus = () => {
    const path = `sessions/${id}`;

    window.fetch(
      `${process.env.API_PATH}${path}`,
      { method: 'GET' }
    )
      .then((response) => {
        if (!response.ok) {
          const error = new Error('Network response was not ok');
          error.status = response.status;
          throw error;
        }
        return response.json();
      })
      .then((response) => {
        setSessionState(response);
        if (response.status < 1) {
          tryAgainAfterDelay();
        }
      })
      .catch((error) => {
        if (error.status === 429) {
          setSessionState({ status: 3 });
        } else if (error.status === 404) {
          setSessionState({ status: 4 });
        } else {
          setSessionState({ status: 2 });
        }
      });
  };

  return (
    <>
      {sessionState.status === 0 && <SessionAccessStatusStarting />}
      {
        sessionState.status === 1
        && <SessionAccessStatusRunning hostname={sessionState.hostName} />
      }
      {
        sessionState.status === 2
        && <SessionAccessStatusError reload={setStartingAndTryAgain} />
      }
      {
        sessionState.status === 3
        && <SessionAccessStatusBusy reload={setStartingAndTryAgain} />
      }
      {
        sessionState.status === 4
        && <SessionAccessStatusExpired />
      }
    </>
  );
};

SessionAccessStatus.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SessionAccessStatus;
