import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import pcAcom from '../../images/pc_acom.png';

const Banner = () => (
  <Grid
    container
    item
    spacing={4}
  >
    <Grid item>
      <Typography variant="h3">
        {'Try the online demo now!'}
      </Typography>
      <Typography variant="subtitle1">
        {'Explore the interface and features of 2N'}
        <sup>{'®'}</sup>
        {' Access Commander at your own pace'}
      </Typography>
    </Grid>
    <Grid container item justify="center">
      <img
        alt="ACOM"
        src={pcAcom}
        width="300"
      />
    </Grid>
  </Grid>
);

export default Banner;
