import { hot } from 'react-hot-loader/root';
import React from 'react';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import 'gotham-fonts/css/gotham-rounded.css';

import TagManager from 'react-gtm-module';

import Layout from './components/Layout';

const tagManagerArgs = {
  gtmId: 'GTM-TBH94QC',
};

TagManager.initialize(tagManagerArgs);

const primaryColor = '#0088ef';
const errorColor = '#ff200d';
const warningColor = '#ff990d';
const successColor = '#1fa519';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: warningColor,
    },
    success: {
      main: successColor,
    },
  },
  typography: {
    h3: {
      fontFamily: 'Gotham Rounded Light',
    },
    h5: {
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Gotham Rounded Book',
    },
  },
  overrides: {
    MuiCardHeader: {
      root: {
        padding: defaultTheme.spacing(3),
      },
    },
    MuiCardContent: {
      root: {
        padding: defaultTheme.spacing(0, 3),
      },
    },
    MuiCardActions: {
      root: {
        padding: defaultTheme.spacing(3),
      },
    },
  },
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Layout />
  </MuiThemeProvider>
);

export default hot(App);
