import React from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import InfoAboutDemoText from '../InfoAboutDemoText';
import StatusCard from '../StatusCard';

const useStyles = makeStyles((theme) => ({
  fabProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  wrapper: {
    position: 'relative',
  },
}));

const SessionAccessStatusStarting = () => {
  const classes = useStyles();

  return (
    <StatusCard
      actions={(
        <Button
          color="primary"
          disabled
          variant="contained"
        >
          {'Let\'s start'}
        </Button>
      )}
      content={<InfoAboutDemoText />}
      header={'The demo is being prepared'}
      icon={(
        <div className={classes.wrapper}>
          <Avatar className={classes.success}>
            <HourglassEmptyIcon />
          </Avatar>
          <CircularProgress className={classes.fabProgress} size={52} />
        </div>
      )}
    />
  );
};

export default SessionAccessStatusStarting;
