import React from 'react';

import Carousel from '../Carousel';
import Page from '../Page';
import PageItem from '../PageItem';
import SessionAccessStatusExpired from '../SessionAccessStatusExpired';

const ExpiredPage = () => (
  <Page>
    <PageItem>
      <SessionAccessStatusExpired />
    </PageItem>
    <PageItem>
      <Carousel />
    </PageItem>
  </Page>
);

export default ExpiredPage;
