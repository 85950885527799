import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const PageItem = ({ children }) => (
  <Grid
    item
    xs
  >
    {children}
  </Grid>
);

PageItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageItem;
