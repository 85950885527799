import React from 'react';

import AppBar from '../AppBar';
import MainContent from '../MainContent';

const Layout = () => (
  <>
    <AppBar />
    <MainContent />
  </>
);

export default Layout;
